import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import Login from './Seperate/Login';
import Dashboard from './Seperate/Dashboard';
import Forgetpassword from './Seperate/Forgetpassword';
import Otp from './Seperate/Otp';
import Addblog from './Seperate/Addblog';
import Editblog from './Seperate/Editblog';
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from './Auth/ProtectedRoute';
import Errorpage from './Seperate/ErrorPage';
import Uploadproduct from './Seperate/Uploadproduct';
import Editproduct from './Seperate/Editproduct';
import Resetpassword from './Seperate/Resetpassword';
import Faqaddblogs from './Seperate/Faqaddblogs';
import Faqeditblogs from './Seperate/Faqeditblogs';
import Homecontent from "./Seperate/General"
import Addhomecontent from "./Seperate/Addhomecontent"
import Edithomecontent from "./Seperate/Edithomecontent"
import AddTrendingCategories from './Seperate/AddTrendingCategories';
import EditTrendingContent from './Seperate/EditTrendingContent';
import Addhomebanners from './Seperate/Addhomebanners';
import Editbannercontent from './Seperate/Editbannercontent';
import Addoffercontent from './Seperate/Addoffercontent';
import Editoffercontent from "./Seperate/Editoffercontent"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route exact path="/dashboard/blogs"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route exact path="/dashboard/userlist"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route exact path="/dashboard/buyproduct"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route exact path="/dashboard/sellproduct"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route exact path="/dashboard/category"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route exact path="/uploadproduct"
            element={
              <ProtectedRoute>
                <Uploadproduct />
              </ProtectedRoute>
            }
          />
          
          <Route exact path="/editproduct/:id"
            element={
              <ProtectedRoute>
                <Editproduct />
              </ProtectedRoute>
            }
          />
          <Route exact path="/dashboard/product"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route exact path="/dashboard/influencer"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }category
          />
          <Route exact path="/dashboard/general"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
           <Route exact path="/addblog"
            element={
              <ProtectedRoute>
                <Addblog />
              </ProtectedRoute>
            }
          />
          <Route exact path="/addblog"
            element={
              <ProtectedRoute>
                <Addblog />
              </ProtectedRoute>
            }
          />
           <Route exact path="/addhomecontent"
            element={
              <ProtectedRoute>
                <Addhomecontent />
              </ProtectedRoute>
            }
          />
            <Route exact path="/addhomebanners"
            element={
              <ProtectedRoute>
                <Addhomebanners />
              </ProtectedRoute>
            }
          />
          <Route exact path="/addoffercontent"
            element={
              <ProtectedRoute>
                <Addoffercontent />
              </ProtectedRoute>
            }
          />

          
          <Route exact path="/editblog/:id"
            element={
              <ProtectedRoute>
                <Editblog />
              </ProtectedRoute>
            }
          />
          <Route exact path="/edithomecontent/:id"
            element={
              <ProtectedRoute>
                <Edithomecontent />
              </ProtectedRoute>
            }
          />
          <Route exact path="/editbannercontent/:id"
            element={
              <ProtectedRoute>
                <Editbannercontent />
              </ProtectedRoute>
            }
          />
           <Route exact path="/editoffercontent/:id"
            element={
              <ProtectedRoute>
                <Editoffercontent />
              </ProtectedRoute>
            }
          />
          <Route exact path="/addCategoriesContent"
            element={
              <ProtectedRoute>
                <AddTrendingCategories />
              </ProtectedRoute>
            }
          />
          <Route exact path="/editTrendingcontent/:id"
            element={
              <ProtectedRoute>
                <EditTrendingContent />
              </ProtectedRoute>
            }
          />
          <Route exact path="/forgetpassword" element={<Forgetpassword />} />
          <Route exact path="/resetpassword/:token" element={<Resetpassword />} />
          <Route exact path="/otp" element={<Otp />} />
          <Route exact path="*" element={<Errorpage />} />
          <Route exact path="/dashboard/stock" element={<Dashboard /> } />
          <Route exact path="/dashboard/gst" element={<Dashboard /> } />
          <Route exact path="/dashboard/faq" element={<Dashboard /> } />
          <Route exact path="/dashboard/socialmedia" element={<Dashboard /> } />
          <Route exact path="/dashboard/faqaddblogs" element={<Faqaddblogs /> } />
          <Route exact path="/dashboard/faqeditblogs/:id" element={<Faqeditblogs /> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
