import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from 'react-bootstrap';
import { IoMdRemoveCircleOutline } from "react-icons/io";
import Select from 'react-select';
import Dashboard from './Dashboard';
import { IoArrowBackCircleOutline } from "react-icons/io5";


function Editproduct() {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { productData } = location.state || [];
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedSubOption, setSelectedSubOption] = useState("")
    const [skinCareSubOption, setSkinCareSubOption] = useState(null);
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [subCategoryId, setSubCategoryId] = useState("");
    const [gst, setGst] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [mainImagePreview, setMainImagePreview] = useState([]);
    const [imagesToUpdate, setImagesToUpdate] = useState([]);
    const [imageID, setImageID] = useState([])
    const [fileError, setFileError] = useState('');
    const fileInputRef = useRef(null);

    // console.log("imageID----", imageID);
    // console.log("mainImagePreview----", mainImagePreview);
    // console.log("imagesToUpdate----", imagesToUpdate);




    const options = category.map((data) => {
        return {
            value: data.name,
            label: data.name,
            id: data._id
        }
    })

    const skinCareOptions = subCategory.map((data) => {
        return {
            value: data.name,
            label: data.name,
            id: data._id
        }
    })

    const handleSkinCareSubOption = (option) => {
        setSkinCareSubOption(option.value);
        setSubCategoryId(option.id)
        formik.setFieldValue('subCategory', option.value);
        formik.setFieldValue('subCategoryId', option.id);
    };

    const handleSelectOption = (option) => {
        setSelectedOption(option.value);
        setCategoryId(option.id)
        formik.setFieldValue('category', option.value);
        formik.setFieldValue('categoryId', option.id);
    };

    const getCategories = async () => {
        try {
            let params = {
                url: `getcategories`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            console.log("responseresponse",response);
            
            if (response.status === true) {
                setGst(response.Gst)

                const { data: categoriesList } = response;
                setCategory(categoriesList);
                const selectedCategoryData = categoriesList.find(({ name }) => name === selectedOption);
                if (!selectedCategoryData) {
                    setCategoryId(productData._id);
                    setSelectedOption(productData.category);
                    setSubCategoryId(productData.subCategoryId)
                } else {
                    setCategoryId(selectedCategoryData._id);
                    setSelectedOption(selectedCategoryData.name);
                    setSubCategory(selectedCategoryData.subCategories);
                }
            } else {
                setCategory([]);
                setSubCategory([]);
            }
        } catch (error) {
            toast.error("Something Went Wrong...")
        }
    }

    useEffect(() => {
        getCategories();
    }, [selectedOption]);

    const calculateFinalPrice = (price, discount) => {
        if (price && discount) {
            const discountPrice = price * (discount / 100);
            return price - discountPrice;
        }
        return price;
    };


    const formik = useFormik({
        
        initialValues: {
            productname: productData?.productname || "",
            description: productData?.description || '',
            price: productData?.price || '',
            Gstprice:productData?.gst_price || '',
            category: productData?.category || '',
            categoryId: productData?.categoryId || "",
            subCategory: productData?.subCategory || '',
            subCategoryId: productData?.subCategoryId || "",
            shade: productData?.shade || '',
            quantity: productData?.quantity || '',
            size: productData?.size || '',
            discount: productData?.discount || '',
            finalprice: productData ? calculateFinalPrice(productData.price, productData.discount) : '',
            media: productData?.productimages || [],
            imageID: []
        },
        validationSchema: Yup.object({
            productname: Yup.string().required('*Productname is required'),
            description: Yup.string().required('*Description is required'),
            price: Yup.string().required('*Price is required'),
            // shade: Yup.string().required('*Shade is required'),
            quantity: Yup.string().required('*Quantity is required'),
            size: Yup.string().required('*Size is required'),
        }),
        onSubmit: async (values) => {
            if (mainImagePreview.length === 0) {
                setFileError('*Please select at least one file.');
                return;
            }

            let hasInvalidFile = false;
            imagesToUpdate.forEach(fileObj => {
                const file = fileObj.file;
                const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
                const isVideo = file.type === 'video/mp4';
                const isValidImage = isImage && file.size <= 2 * 1024 * 1024; // 2 MB in bytes
                const isValidVideo = isVideo && file.size <= 30 * 1024 * 1024; // 30 MB in bytes

                if (!isValidImage && !isValidVideo) {
                    hasInvalidFile = true;
                }
            });

            if (hasInvalidFile) {
                setFileError('*Each image must be less than 2MB and each video less than 30MB');
                return;
            }
console.log();

            // setFileError('');
            const discountPrice = values.Gstprice * (values.discount / 100)
            const formData = new FormData();
            
            formData.append("id", id);
            formData.append('productname', values.productname);
            formData.append('description', values.description);
            formData.append('price', values.price);
            formData.append('category', values.category);
            formData.append('gst_price', values.Gstprice);
            formData.append('categoryId', values.categoryId);
            formData.append('subCategory', values.subCategory);
            formData.append('subCategoryId', values.subCategoryId);
            formData.append('discount', values.discount);
            formData.append('discount_price', discountPrice);
            formData.append('finalprice', values.finalprice);
            formData.append('shade', values.shade);
            formData.append('quantity', values.quantity);
            formData.append('size', values.size);

            imagesToUpdate.forEach(files => {
                formData.append("media", files.file);
            });

            if (imageID.length > 0) {
                formData.append("imageID", JSON.stringify(imageID));
            }

            try {
                setIsLoading(true);
                const params = {
                    url: `add&update-productData`,
                    method: "POST",
                    data: formData,
                    header: "image"
                };

                const response = await makeApiRequest(params);
                if (response.status === true) {
                    setIsLoading(false);
                    toast.success(response.message);
                    setTimeout(() => {
                        navigate('/dashboard/product');
                    }, 1000);
                } else {
                    toast.warn(response.message);
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
            }
        }
    });

    const handleChange = (e) => {
        formik.handleChange(e);

    };
    
    useEffect(() => {

        let priceValue = formik.values.price;
        let discount = formik.values.discount || 0
        let finalPrice = 0;
        let gstprice = 0;

        // convert gst price
        if (formik.values.price > 0 && formik.values.price != "") {
            console.log("gst",typeof gst);
            
            const GstCal = priceValue * (gst / 100);
            gstprice = Number(priceValue) + Number(GstCal)
            formik.setFieldValue('Gstprice', Math.round(gstprice))
        }
        else {
            formik.setFieldValue('Gstprice', "")
        }

        // final price include discount
        if (formik.values.price > 0 && formik.values.discount > 0 && formik.values.discount != "" && formik.values.price != "") {
            const Gst = Math.round(gstprice * Number(discount / 100));
            finalPrice = gstprice - Gst;
            formik.setFieldValue('finalprice', Math.round(finalPrice));
        } else {
            formik.setFieldValue('finalprice', Math.round(gstprice));
        };
    }, [formik.values,gst]);


    useEffect(() => {

        if (productData) {
            console.log(productData.gst_price,"productData");
            
        
            formik.setValues({
                productname: productData.productname || "",
                description: productData.description || '',
                price: productData.price || '',
                Gstprice:productData.gst_price || '',
                category: productData.category || '',
                categoryId: productData.categoryId || "",
                subCategory: productData.subCategory || '',
                subCategoryId: productData.subCategoryId || "",
                shade: productData.shade || '',
                quantity: productData.quantity || '',
                discount: productData.discount || '',
                finalprice: calculateFinalPrice(productData.price, productData.discount),
                size: productData.size || '',
                media: null
            });
            const showImages = productData.productimages.map(data => ({
                media: data.media,
                id: data._id
            }));
            setMainImagePreview(showImages || []);
            setImagesToUpdate([]);
        }
    }, [productData]);


    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const updatedPreview = files.map(file => {
            const mediaType = file.type.startsWith('video') ? 'video' : 'image';
            const preview = URL.createObjectURL(file);
            return { media: preview, file, type: mediaType };
        });

        setMainImagePreview(prev => [...prev, ...updatedPreview]);
        setImagesToUpdate(prev => [...prev, ...updatedPreview]);
    };

    const handleRemoveImage = (index, imageSrc) => {
        console.log("index000", index);

        URL.revokeObjectURL(imageSrc.media);

        const updatedImagesToUpdate = imagesToUpdate.filter((_, i) => i !== index - 1);
        setImagesToUpdate(updatedImagesToUpdate);

        const updatedMainImages = mainImagePreview.filter((_, i) => i !== index);
        setMainImagePreview(updatedMainImages);

        if (imageSrc.id) {
            setImageID(prev => [...prev, imageSrc.id]);
            
        }

        if (!imageSrc.id && fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };





    // const handleRemoveImage = (index, imageSrc) => {
    //     // Remove the image from the preview and imagesToUpdate
    //     const updatedMainImages = mainImagePreview.filter((_, i) => i !== index);
    //     setMainImagePreview(updatedMainImages);

    //     const updatedImagesToUpdate = imagesToUpdate.filter((_, i) => i !== index);
    // setImagesToUpdate(updatedImagesToUpdate);

    //     if (!imageSrc.id && fileInputRef.current) {
    //         fileInputRef.current.value = null; 
    //     }

    //     if (imageSrc.id) {
    //         setImageID(prev => [...prev, imageSrc.id]);
    //     }
    // };


    // const handleRemoveImage = (index, imageSrc) => {
    //     const updatedMainImages = mainImagePreview.filter((_, i) => i !== index);
    //     setMainImagePreview(updatedMainImages);
    //     // setImagesToUpdate(prev => prev.filter(img => img.index !== index));
    //     setImagesToUpdate(prev => prev.filter((_, i) => i !== index));
    //     if (updatedMainImages.length === 0 && fileInputRef.current) {
    //         fileInputRef.current.value = null;
    //     }
    //     imageID.push(imageSrc.id);
    //     setImageID(imageID);

    // };


    const isVideo = (url) => {
        return url.endsWith('.mp4') || url.endsWith('.webm') || url.endsWith('.ogg');
    };


    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-uploadproduct min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <Dashboard />
                                </div>
                                <div className='col-md-12 ms-sm-auto col-lg-10 px-md-4 m-0 p-0'>
                                    <h2 className='fw-bold'><a href='/dashboard/product'><IoArrowBackCircleOutline className='me-2' style={{ color: "rgb(235 138 152)" }} /></a> Edit Product</h2>
                                    <div className='custom-inside-upload mt-4'>
                                        <div className='row mb-3'>
                                            <div className="col-sm-4 mt-2">
                                                <Select
                                                    options={options}
                                                    onChange={handleSelectOption}
                                                    value={options.find(option => option.value === selectedOption)}
                                                    placeholder="Select an option"
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {selectedOption && (
                                        <div className='custom-skin-care-option'>
                                            <h2> {selectedOption} </h2>
                                            <div className='custom-skincare'>
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputName" className="col-sm-3 col-form-label text-lg-end">Product Name</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="productname"
                                                                value={formik.values.productname}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputName"
                                                                placeholder='ProductName'
                                                            />
                                                            {formik.touched.productname && formik.errors.productname ? (
                                                                <div className="text-danger">{formik.errors.productname}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputDescription" className="col-sm-3 col-form-label text-lg-end">Description</label>
                                                        <div className="col-sm-6">
                                                            <textarea
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="description"
                                                                value={formik.values.description}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputDescription"
                                                                placeholder='Description'
                                                            />
                                                            {formik.touched.description && formik.errors.description ? (
                                                                <div className="text-danger">{formik.errors.description}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputPrice" className="col-sm-3 col-form-label text-lg-end">Price</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="price"
                                                                value={formik.values.price}
                                                                onChange={handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputPrice"
                                                                placeholder='Price'
                                                            />
                                                            {formik.touched.price && formik.errors.price ? (
                                                                <div className="text-danger">{formik.errors.price}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="Gstprice" className="col-sm-3 col-form-label text-lg-end">Gst Price({gst}%)</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="Gstprice"
                                                                value={formik.values.Gstprice
                                                                }
                                                                onChange={handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="Gstprice"
                                                                placeholder='Gstprice'
                                                            />
                                                            {/* {formik.touched.price && formik.errors.price ? (
                                                                <div className="text-danger">{formik.errors.price}</div>
                                                            ) : null} */}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputDiscount" className="col-sm-3 col-form-label text-lg-end">Discount Percentage(%)</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="discount"
                                                                value={formik.values.discount}
                                                                onChange={handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputDiscount"
                                                                placeholder='Discount Percentage'
                                                            />
                                                            {formik.touched.discount && formik.errors.discount ? (
                                                                <div className="text-danger">{formik.errors.discount}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputFinalprice" className="col-sm-3 col-form-label text-lg-end">Final Price</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="finalprice"
                                                                value={formik.values.finalprice}
                                                                id="inputFinalprice"
                                                                placeholder='finalprice'
                                                            />
                                                            {formik.touched.finalprice && formik.errors.finalprice ? (
                                                                <div className="text-danger">{formik.errors.finalprice}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className='row mb-3'>
                                                        <label htmlFor="dropdownSkinCare" className="col-sm-3 col-form-label text-lg-end">Select a {selectedOption} Option:</label>
                                                        <div className="col-sm-6">
                                                            <Select
                                                                options={skinCareOptions}
                                                                name="subCategory"
                                                                onChange={handleSkinCareSubOption}
                                                                placeholder="Select an option"
                                                                value={skinCareOptions.find(option => option.id === formik.values.subCategoryId)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-4'>
                                                        <label htmlFor='formFileMain' className='form-label col-sm-3 text-lg-end'>
                                                            Update Image
                                                        </label>
                                                        <div className='col-sm-6'>
                                                            <input
                                                                className='form-control'
                                                                type='file'
                                                                id='formFileMain'
                                                                name='mainImagePaths'
                                                                multiple
                                                                accept='image/jpeg,image/jpg,image/png,video/mp4'
                                                                onChange={(event) => handleImageChange(event)}
                                                                ref={fileInputRef}
                                                            />
                                                            {fileError && <div className='text-danger'>{fileError}</div>}

                                                            {formik.errors.mainImagePaths && formik.touched.mainImagePaths ? (
                                                                <div className='text-danger'>{formik.errors.mainImagePaths}</div>
                                                            ) : null}

                                                            <div className='image-preview mt-3'>
                                                                {mainImagePreview.map((media, index) => (
                                                                    <div key={index} className='preview-container'>
                                                                        {/* Check if the URL is a video based on file extension */}
                                                                        {(media.media.endsWith('.mp4') || media.type === 'video') ? (
                                                                            <video
                                                                                src={media.media}
                                                                                controls
                                                                                className='video-preview'
                                                                                style={{ width: '150px', height: '100px', objectFit: 'cover' }}
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={media.media}
                                                                                alt={`preview-${index}`}
                                                                                className='img-thumbnail'
                                                                                style={{ width: '150px', height: '100px', objectFit: 'cover' }}
                                                                            />
                                                                        )}
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-danger btn-sm'
                                                                            onClick={() => handleRemoveImage(index, media)}
                                                                        >
                                                                            <IoMdRemoveCircleOutline />
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="row mb-3 mt-4">
                                                        <label htmlFor="inputShade" className="col-sm-3 col-form-label text-lg-end">Fragrance</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="shade"
                                                                value={formik.values.shade}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputShade"
                                                                placeholder='Enter Fragrance'
                                                            />
                                                            {formik.touched.shade && formik.errors.shade ? (
                                                                <div className="text-danger">{formik.errors.shade}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputQuantity" className="col-sm-3 col-form-label text-lg-end">Stock Limit</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="quantity"
                                                                value={formik.values.quantity}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputQuantity"
                                                                placeholder='Enter Stock Limit'
                                                            />
                                                            {formik.touched.quantity && formik.errors.quantity ? (
                                                                <div className="text-danger">{formik.errors.quantity}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputSize" className="col-sm-3 col-form-label text-lg-end">Quantity (ml or grm)</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="size"
                                                                value={formik.values.size}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputSize"
                                                                placeholder='Enter Quantity'
                                                            />
                                                            {formik.touched.size && formik.errors.size ? (
                                                                <div className="text-danger">{formik.errors.size}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className='text-center mt-4'>
                                                        {
                                                            isLoading == true ? (
                                                                <button className="liquidity-btn-1" >
                                                                    <Spinner animation="border" />
                                                                </button>
                                                            ) : (
                                                                <button className='liquidity-btn-1' type='submit'>Submit</button>
                                                            )
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Editproduct