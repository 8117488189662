import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { makeApiRequest } from "../AxiosServices/apiCall";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

function Faq() {

  const navigate = useNavigate()
  const [faqlist, setFaqlist] = useState([])

  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  // const [searchTerm, setSearchTerm] = useState(''); 


  //   const handleSearch = (event) => {
  //     const value = event.target.value;
  //     setSearchTerm(value);

  //     // Filter products based on the selected category and the search term
  //     let filtered = faqlist.filter(faq =>
  //         faq.faqTitle.toLowerCase().includes(value.toLowerCase())
  //     );

  //     setFaqlist(filtered);
  // };

  // const handleDeleteClick = (row) => {
  //   setSelectedRow(row);
  //   setShowModal(true);
  // };

  // const confirmDelete = async () => {
  //   if (selectedRow) {
  //     await DeleteFaq(selectedRow);
  //   }
  //   setShowModal(false);
  // };

  // const handleClose = () => setShowModal(false);

  // delete
  const DeleteFaq = async (row) => {
    try {
      const swalResult = await Swal.fire({
        title: "Are you sure you want to Delete?",
        icon: "question",
        iconHtml: "?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        showCloseButton: true
      });
      if (swalResult.isConfirmed) {
        let datas = {
          id: row._id,
          type: "delete"
        };
        let params = {
          url: `faqUpdate`,
          method: 'POST',
          data: datas
        };
        let response = await makeApiRequest(params);
        if (response.status === true) {
          toast.success(response.message);
          getfaq();
        } else {
          toast.error("Failed to delete FAQ");
        }
      }
      else{
        window.location.reload();

      }

    } catch (error) {
      console.log('error', error);
      toast.error("An error occurred");
    }
  };

  const columnsone = [
    {
      name: 'S.No',
      selector: (row, index) => index + 1,
      sortable: true,
      width: '80px'
    },
    {
      name: 'Faq Title',
      selector: row => row.faqTitle,
      sortable: true,
      width: '300px'
    },
    {
      name: 'Faq Description',
      selector: row => row.faqDescription,
      sortable: true,
      id: 'faqDescription',
      width: '300px',
      cell: row => <div dangerouslySetInnerHTML={{ __html: row.faqDescription }} />
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
    },
    {
      name: 'Action', selector: row => {
        return (
          <div className='ms-1 d-flex'>
            <p onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }}>
              <FaEdit className='me-2' />
            </p>

            <p onClick={() => DeleteFaq(row)} style={{ cursor: 'pointer', margin: "0" }}>
              <RiDeleteBin6Line />
            </p>
          </div>
        )
      }, sortable: true, id: 'action', width: '100px'
    },
  ];
  const handleEdit = async (row) => {
    navigate(`/dashboard/faqeditblogs/${row._id}`, { state: { faqdata: row } })
  }

  const getfaq = async () => {
    try {
      let params = {
        url: `getfaq`,
        method: 'GET',
      };
      let response = await makeApiRequest(params);
      if (response.status === true) {
        const faqList = response.data;
        setFaqlist(faqList);
      } else {
        setFaqlist([]);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getfaq();
  }, [])


  return (
    <div className='App'>
      <ToastContainer />
      <div className='container-fluid'>
        <div className='custom-faq'>
          {/* <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this FAQ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={confirmDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal> */}
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <h2 className='fw-bold'>Faq</h2>
                <a href='/dashboard/faqaddblogs'> <button className='liquidity-btn-1'>Add Faq</button></a>
                {/* <div className="col-sm-4 mt-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Products"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div> */}

                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='mt-2 liquidity-table-1 '>

                      <DataTable
                        columns={columnsone}
                        data={faqlist}
                        theme="solarized"
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        dense
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Faq