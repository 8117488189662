import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component'
import { CiCirclePlus } from "react-icons/ci";
// import render from "../assets/images/favicon.png"
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BsLayoutTextWindowReverse } from "react-icons/bs";
import { saveAs } from 'file-saver';
import { CiSaveUp1 } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";
import { makeApiRequest } from "../AxiosServices/apiCall";
import { Link, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Modal, Button } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

function Blog() {
    const navigate = useNavigate()
    const [showPagination, setShowPagination] = useState(true);
    const [blog, setBlog] = useState([])
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    // Handle page change
    const handlePageChange = page => {
        setCurrentPage(page);
    };
    const handleButtonClick = () => {
        setShowPagination(!showPagination);
    };

    const [selectedColumn, setSelectedColumn] = useState(null);

    const handleFilterChange = (columnId) => {
        setSelectedColumn(columnId);
    };

    const getFilteredColumns = () => {
        // console.log('selectedColumn--', selectedColumn);
        if (!selectedColumn) return columnsone;
        return columnsone.filter(col => col.id === selectedColumn);

    };

    const getblogdatas = async () => {
        try {
            let params = {
                url: `getblog`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status === true) {
                const blogList = response.data;
                // console.log('blogList---',blogList);
                setBlog(blogList);
            } else {
                setBlog([]);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getblogdatas();
    }, [])

    const convertToIST = (dateString) => {
        const date = new Date(dateString);
        const options = {
            timeZone: 'Asia/Kolkata',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        };
        return date.toLocaleString('en-IN', options);
    }


    const DeleteBlog = async (row) => {
        try {
            const swalResult = await Swal.fire({
                title: "Are you sure you want to Delete?",
                icon: "question",
                iconHtml: "?",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                showCancelButton: true,
                showCloseButton: true
            });
            if (swalResult.isConfirmed) {
                let datas = {
                    blogId: row._id
                }
                let params = {
                    url: `blogdelete`,
                    method: 'POST',
                    data: datas
                };
                let response = await makeApiRequest(params)
                    .then((response) => {
                        toast.success(response.message);
                        getblogdatas();
                    })
                    .catch((error) => {
                        console.log('error', error);
                    })
            }
            else {
                window.location.reload();
            }
        } catch (error) {
            console.log("error--",error);         
        }
    };

    const filteredBlogs = blog.filter((b) =>
        b.blogTitle.toLowerCase().includes(searchQuery.toLowerCase())
        || b.blogDescription.toLowerCase().includes(searchQuery.toLowerCase())
        || b.blogType.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const columnsone = [
        { name: 'S.no', selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage, sortable: true, id: 'sno', width: '80px' },
        { name: 'Blog Title', selector: row => row.blogTitle, sortable: true, id: 'blogTitle', width: '200px' },
        { name: 'Blog Type', selector: row => row.blogType, sortable: true, id: 'blogType', width: '200px' },
        {
            name: 'Blog Description',
            selector: row => row.blogDescription,
            sortable: true,
            id: 'blogDescription',
            width: '450px',
            cell: row => <div dangerouslySetInnerHTML={{ __html: row.blogDescription }} />
        },
        {
            name: 'Blog Image',
            selector: row => {
                return (
                    <div className='custom-img-pre'>
                        <img style={{ width: "50px" }} src={row.blogImage} />
                    </div>
                )
            },
            sortable: true,
            id: 'blogImage',
            width: '100px'
        },
        { name: 'Date', selector: row => convertToIST(row.createdAt), sortable: true, id: 'createdAt', width: '250px' },
        {
            name: 'Action', selector: row => {
                return (
                    <div className='ms-1 d-flex'>
                        <p onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }}>
                            <FaEdit className='me-2' />
                        </p>
                        <p onClick={() => DeleteBlog(row)} style={{ cursor: 'pointer' }}>
                            <RiDeleteBin6Line />
                        </p>
                    </div>
                )
            }, sortable: true, id: 'action', width: '100px'
        },
    ];

    const handleEdit = async (row) => {
        // console.log("row-----",row);
        navigate(`/editblog/${row._id}`, { state: { blogData: row } })
    }

    const [isListView, setIsListView] = useState(false);

    const handleViewToggle = () => {
        setIsListView(!isListView);
    };

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };



    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-blog min-vh-100 text-white'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custom-blog-inside'>
                                    <h2 className='fw-bold text-dark'>Blogs</h2>
                                    <div className='custom-blog-search d-lg-flex align-items-baseline'>
                                        <input
                                            type="text"
                                            id="inputPassword6"
                                            class="form-control input-text-2"
                                            aria-describedby="passwordHelpInline"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <a href='/addblog'><button className='liquidity-btn-1'>ADD Blogs</button></a>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='liquidity-table-1 mt-3'>
                                    <div>
                                        {isListView ? (
                                            <div>
                                                {blog.map((row, index) => (
                                                    <div key={index} style={{ marginBottom: '20px', border: '1px solid #e98d9d', padding: '10px' }}>
                                                        {getFilteredColumns().map((col, colIndex) => (
                                                            <div key={colIndex} style={{ marginBottom: '10px', display: "flex" }}>
                                                                <strong>{col.name}:</strong> {row[col.id]}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <DataTable
                                                columns={columnsone}
                                                data={filteredBlogs}
                                                theme="solarized"
                                                defaultSortAsc={true}
                                                pagination={showPagination}
                                                paginationTotalRows={blog.length}
                                                paginationPerPage={rowsPerPage}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                                                onChangePage={handlePageChange}
                                                highlightOnHover
                                                dense
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showButton && (
                            <button onClick={scrollToTop} id="scrollToTopBtn" title="Go to top">
                                &#8679;
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog