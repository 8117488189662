import React from 'react'
import $ from 'jquery'
import { GrClose } from 'react-icons/gr';
import { MdWebAsset } from 'react-icons/md';
import { RiWallet3Fill } from 'react-icons/ri';
import { RiExchangeDollarLine } from 'react-icons/ri';
import { PiClockClockwiseBold } from 'react-icons/pi';
import { FaListAlt } from 'react-icons/fa';
import { TbLogout } from 'react-icons/tb';
import { AiOutlineSecurityScan } from 'react-icons/ai';
import { ImProfile } from "react-icons/im";
import { CgProfile } from "react-icons/cg";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import '../Assets/css/Dashboard.css'
import Blogs from '../Seperate/Blogs';
import Userlist from './Userlist';
import Buyproduct from './Buyproduct';
import Sellproduct from './Sellproduct';
import Uploadproduct from './Uploadproduct';
import Category from './Category';
import Product from './Product';
import Influencer from './Influencer'
import Stock from './Stock';
import Gst from './Gst';
import Faq from './Faq';
import General from './General'
import { MdAccountCircle } from "react-icons/md";
import { MdLeaderboard } from "react-icons/md";
import { FaDice } from "react-icons/fa";
import { BiLogoWindows } from "react-icons/bi";
import { SiSellfy } from "react-icons/si";
import { MdHome } from 'react-icons/md';
import { MdCategory } from "react-icons/md";
import { GiCoinflip } from "react-icons/gi";
import { FaProductHunt } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { AiOutlineStock } from "react-icons/ai";
import { MdPersonSearch } from "react-icons/md";
import { FaQrcode } from "react-icons/fa";
import Socialmedia from './Socialmedia';
import { IoShareSocialSharp } from "react-icons/io5";


$(document).ready(function () {
    if ($(window).width() < 600) {       // if width is less than 600px
        $(".nav-link").click(function () {
            $("#mySidenav").css("width", "0px");
        });
    }
    else {
        // $('#v-pills-asset-tab').click(function () {
        //   location.reload();
        // });
    }

    $("#mobile-three-line-collapse").click(function () {
        $("#mySidenav").css("width", "250px");
    });
    $("#mobile-close-collapse").click(function () {
        $("#mySidenav").css("width", "0px");
    });
    $(".dashboard-profile-table-tabs-section-hide").hide();
    $(".details-button-navigation button").click(function () {
        $(".dashboard-profile-section-hide").hide();
        $(".dashboard-profile-table-tabs-section-hide").show();
        $("#mySidenav").css("width", "100px");
        $(".dashboard-main-section").css("margin-left", "100px");
        $(".dashboard-navbar-brand img").css("width", "50px");
        $(".dashboard-navbar-brand img").css("height", "50px");
        $("#mobile-close-arrow-left").hide();
        $(".dashboard-text-1").hide();
        $("#mobile-close-arrow-right").show();
    });
    $(".button-dashboard-table-back").click(function () {
        $(".dashboard-profile-section-hide").show();
        $(".dashboard-profile-table-tabs-section-hide").hide();
        $("#mySidenav").css("width", "250px");
        $(".dashboard-main-section").css("margin-left", "250px");
        $(".dashboard-navbar-brand img").css("width", "80px");
        $(".dashboard-navbar-brand img").css("height", "80px");
        $("#mobile-close-arrow-left").show();
        $(".dashboard-text-1").show();
        $("#mobile-close-arrow-right").hide();
    });



    $("#mobile-close-arrow-right").hide();
    $("#mobile-close-arrow-left").click(function () {
        $("#mySidenav").css("width", "100px");
        $(".dashboard-main-section").css("margin-left", "100px");
        $(".dashboard-navbar-brand img").css("width", "50px");
        $(".dashboard-navbar-brand img").css("height", "50px");
        $("#mobile-close-arrow-left").hide();
        $(".dashboard-text-1").hide();
        $("#mobile-close-arrow-right").show();
    });
    $("#mobile-close-arrow-right").click(function () {
        $("#mySidenav").css("width", "250px");
        $(".dashboard-main-section").css("margin-left", "250px");
        $(".dashboard-navbar-brand img").css("width", "80px");
        $(".dashboard-navbar-brand img").css("height", "80px");
        $("#mobile-close-arrow-left").show();
        $(".dashboard-text-1").show();
        $("#mobile-close-arrow-right").hide();
    });


    if ($('#mySidenav').css('width') === '100px') {
        $('#mySidenav').css('a');
    }
});

function Dashboard() {
    const location = useLocation();

    const logOut = () => {
        localStorage.clear();
        window.location.href = "/";
    };

    return (
        <div className='App dashboard-navhide-section'>

            <div className='container-fluid'>
                <div className="row">
                    {/* Sidebar */}
                    <div>
                        <span id="mobile-three-line-collapse">&#9776;</span>
                        <div className="dashboard-sidenav-section" id="mySidenav">
                            <nav
                                id="sidebar"
                                className="col-md-12 col-lg-12 d-md-block sidebar component-navbar"
                            >
                                <div className="d-flex flex-row">
                                    <div className="ms-auto me-3">
                                        <span id="mobile-close-collapse"><GrClose />
                                        </span>
                                    </div>
                                </div>


                                <div className="position-sticky">
                                    <ul className="nav flex-column nav-pills">

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/userlist" ? "active" : ""
                                                    }`}
                                                href="/dashboard/userlist"
                                            >
                                                <MdWebAsset className="sidenav-icon-size-css" /> User List
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/influencer" ? "active" : ""
                                                    }`}
                                                href="/dashboard/influencer"
                                            >
                                                <GiCoinflip className="sidenav-icon-size-css" /> Influencer
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/buyproduct" ? "active" : ""
                                                    }`}
                                                href="/dashboard/buyproduct"
                                            >
                                                <MdLeaderboard className="sidenav-icon-size-css" /> Orders List
                                            </a>
                                        </li>


                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/category" ? "active" : ""
                                                    }`}
                                                href="/dashboard/category"
                                            >
                                                <MdCategory className="sidenav-icon-size-css" /> Category
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/product" ? "active" : ""
                                                    }`}
                                                href="/dashboard/product"
                                            >
                                                <FaProductHunt className="sidenav-icon-size-css" />Product
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/sellproduct" ? "active" : ""
                                                    }`}
                                                href="/dashboard/sellproduct"
                                            >
                                                <SiSellfy className="sidenav-icon-size-css" /> Sold Products
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/stock" ? "active" : ""
                                                    }`}
                                                href="/dashboard/stock"

                                            >
                                                <AiOutlineStock className="sidenav-icon-size-css" /> Stock
                                            </a>
                                        </li>

                                        


                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/gst" ? "active" : ""
                                                    }`}
                                                href="/dashboard/gst"
                                            >
                                                <MdPersonSearch className="sidenav-icon-size-css" /> Site Setting
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/blogs"
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                href="/dashboard/blogs"
                                            >
                                                <BiLogoWindows className="sidenav-icon-size-css " /> Blogs
                                            </a>
                                        </li>


                                        

                                        

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/general" ? "active" : ""
                                                    }`}
                                                href="/dashboard/general"
                                            >
                                                <MdHome className="sidenav-icon-size-css" /> General
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/faq" ? "active" : ""
                                                    }`}
                                                href="/dashboard/faq"
                                            >
                                                <FaQrcode className="sidenav-icon-size-css" /> Faq
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link component-tabs ${location.pathname === "/dashboard/socialmedia" ? "active" : ""
                                                    }`}
                                                href="/dashboard/socialmedia"
                                            >
                                                <IoShareSocialSharp className="sidenav-icon-size-css" /> Social Media
                                            </a>
                                        </li>



                                        <li className="nav-item">
                                            <a
                                                className='nav-link component-tabs'
                                                // href="/"
                                                onClick={logOut}
                                            >
                                                <IoMdLogOut className="sidenav-icon-size-css" /> Log Out
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>

                    {/* Main Content */}
                    <main className="col-md-12 ms-sm-auto col-lg-10 px-md-4">

                        {location.pathname === "/dashboard/blogs" && <Blogs />}
                        {location.pathname === "/dashboard/userlist" && <Userlist />}
                        {location.pathname === "/dashboard/buyproduct" && <Buyproduct />}
                        {location.pathname === "/dashboard/sellproduct" && <Sellproduct />}
                        {location.pathname === "/dashboard/category" && <Category />}
                        {location.pathname === "/dashboard/product" && <Product />}
                        {location.pathname === "/dashboard/influencer" && <Influencer />}
                        {location.pathname === "/dashboard/stock" && <Stock />}
                        {location.pathname === "/dashboard/gst" && <Gst />}
                        {location.pathname === "/dashboard/faq" && <Faq />}
                        {location.pathname === "/dashboard/socialmedia" && <Socialmedia />}
                        {location.pathname === "/dashboard/general" && <General />}

                    </main>

                </div>
            </div>
        </div>
    )
}

export default Dashboard